<template>
  <div class="container mt-4">
    <b-col>
      <b-row>
        <b-col class="d-flex align-items-center">
          <BackButton></BackButton>
          <h5 class="ml-2 mb-0">
            {{ translations.monthly_revenue.details.back_to_list }}
          </h5>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <b-card class="border-0" :header="translations.monthly_revenue.details.page_title" body-class="px-0" header-class="border-0">
            <div class="col-12">
              <div class="row mb-5">
                <div class="col-3">
                  <b>{{ translations.monthly_revenue.details.client }}</b>
                  <p>{{ details.client_name }}</p>
                </div>
                <div class="col-3">
                  <b>{{ translations.monthly_revenue.details.month }}</b>
                  <p>{{ details.year_month }}</p>
                </div>
              </div>
              <div class="row mb-5">
                <div class="col-3">
                  <b>{{ translations.monthly_revenue.details.gross_amount }}</b>
                  <p>{{ details.gross_amount }}</p>
                </div>
                <div class="col-3">
                  <b>{{ translations.monthly_revenue.details.revenue_amount }}</b>
                  <p>{{ details.revenue_share_amount }}</p>
                </div>
                <div class="col-3">
                  <b>{{ translations.monthly_revenue.details.monthly_revenue }}</b>
                  <p>{{ details.net_amount }}</p>
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
    <b-card
      class="border-0 mb-2 mt-3"
      body-class="px-0"
      header-class="border-0">
      <template #header>
        <div class="d-flex align-items-center justify-content-between">
          {{ translations.monthly_revenue.summary.title }}
          <b-button variant="secondary" class="compact-text mr-2" @click="exportMonthlyRevenueSummary">
            <feather type="file"></feather>
            {{ translations.statements.global.export_summary }}
          </b-button>
        </div>
      </template>
      <div>
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col" class="text-center">{{ translations.monthly_revenue.summary.transaction_type }}</th>
              <th scope="col" class="text-center">{{ translations.monthly_revenue.summary.unit }}</th>
              <th scope="col" class="text-center">{{ translations.monthly_revenue.summary.quantity }}</th>
              <th scope="col" class="text-center">{{ translations.monthly_revenue.summary.unit_value }}</th>
              <th scope="col" class="text-center">{{ translations.monthly_revenue.summary.amount }}</th>
            </tr>
          </thead>
          <tbody v-if="details.summary && details.summary.items.length">
            <tr v-for="(item, trIndex) in details.summary.items" :key="trIndex">
              <td class="text-center">
                {{ item.type }}
                <span v-if="isPEPM(item.type)">
                  <feather
                    :id="`pepm_observations-${trIndex}`"
                    class="text-info pepm_observations"
                    type="info"></feather>
                  <b-tooltip
                    custom-class="pepm-tooltip"
                    :target="`pepm_observations-${trIndex}`"
                    placement="bottom">{{ item.observations }}</b-tooltip>
                </span>
              </td>
              <td class="text-center">{{ item.unit }}</td>
              <td class="text-center">{{ item.count }}</td>
              <td class="text-center">{{ item.value }}</td>
              <td class="text-center">{{ item.total }}</td>
            </tr>
            <tr v-if="hasSummaryFee">
              <td colspan="4" class="text-right">{{ translations.monthly_revenue.summary.gross_amount }}:</td>
              <td class="text-center">{{ details.summary.total }}</td>
            </tr>
            <tr v-for="(item, trIndex) in details.fees.items" :key="`fee-${trIndex}`">
              <td colspan="3" class="text-right">({{ item.description }})</td>
              <td class="text-right">{{ item.fee_origin }}</td>
              <td class="text-center font-weight-bold">{{ item.fee_value }}</td>
            </tr>
            <tr>
              <td colspan="4" class="text-right font-weight-bold">{{ translations.monthly_revenue.summary.total_amount }}:</td>
              <td class="text-center font-weight-bold">{{ details.net_amount }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td :colspan="5" class="text-center">{{ translations.monthly_revenue.summary.empty }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-card>
    <b-card
      class="border-0 mb-2 mt-3"
      body-class="px-0"
      header-class="border-0">
      <template #header>
        <div class="d-flex align-items-center justify-content-between">
          {{ translations.global.statements }}
          <div>
            <b-button variant="secondary" class="compact-text mr-2" @click="exportMonthlyRevenueDetails">
              <feather type="download"></feather>
              {{ translations.statements.global.export_details }}
            </b-button>
          </div>
        </div>
      </template>
      <financial-list
        v-model="currentPage"
        :header-data="TABLE_HEADERS"
        :events="TABLE_EVENTS"
        :data="statements"
        :disabled="loadingPage"
        :items-per-page="itemsPerPage"
        :total="total"
        :no-items-message="translations.statements.statements_table.empty_statements_list"
        itemRefKey="id"
        @page-changed="loadStatementsList"
        @row-details="openStatementDetails"/>
    </b-card>
  </div>
</template>

<script>
import moment from 'moment';

import BackButton from '@/components/BackButton.vue';
import FinancialList, { associateHeaderDataItem } from '@/components/Financial/FinancialList.vue';
import translations from '@/translations';
import { formatValue, formatDate, formatDatetime } from '@/helpers/finance';
import utils from '@/scripts/tools/utils';

export default {
  name: 'FinanceMonthlyRevenueDetails',
  components: {
    BackButton,
    FinancialList,
  },
  created() {
    this.TABLE_HEADERS = [
      associateHeaderDataItem(this.translations.statements.statements_table.id, 'id'),
      associateHeaderDataItem(this.translations.statements.statements_table.start_end_date, 'start_end_date'),
      associateHeaderDataItem(this.translations.statements.statements_table.balance, 'formatted_total_balance'),
      associateHeaderDataItem(this.translations.statements.statements_table.billing_type, 'billing_type'),
      associateHeaderDataItem(this.translations.statements.statements_table.payer, 'payer_name'),
      associateHeaderDataItem(this.translations.statements.statements_table.billing_period, 'billing_period'),
    ];
    this.TABLE_EVENTS = {
      'row-details': {
        title: translations.finance.financial_list.icons.details,
        icon: 'eye',
      },
    };
    this.clientId = Number(this.$route.params.clientId);
    this.yearMonth = this.$route.params.yearMonth;
  },
  data() {
    return {
      translations: translations.finance,
      details: {},
      statements: [],
      total: 0,
      currentPage: 1,
      itemsPerPage: 5,
      loadingPage: true,
      hasSummaryFee: false,
    };
  },
  async beforeMount() {
    this.loadingPage = true;
    await this.loadMonthlyRevenue();
    await this.loadStatementsList();
    this.loadingPage = false;
  },
  methods: {
    async changePage(page) {
      this.loadingPage = true;
      this.currentPage = page;
      await this.loadStatementsList();
      this.loadingPage = false;
    },
    async loadMonthlyRevenue() {
      try {
        const { clientId, yearMonth } = this;
        const res = await this.$store.dispatch('Financial/getMonthlyRevenue', { clientId, yearMonth });
        this.details = res.data.data;
        if (this.details?.fees?.items?.length) {
          this.hasSummaryFee = true;
        }
      } catch (err) {
        let errDetail = err;
        if (err.response && err.response.data && err.response.data.error && err.response.data.error.detail) {
          errDetail = err.response.data.error.detail;
        }
        const errMsg = `${this.translations.monthly_revenue.errors.fetch_details_error} ${errDetail}`;
        this.$noty.error(errMsg);
      }
    },
    async loadStatementsList() {
      if (!this.details.client_id) {
        return; // getting statements without client id will return items of other clients
      }
      const endDateFrom = moment(this.yearMonth, 'YYYY-MM').format('YYYY-MM-DD');
      const endDateUntil = moment(this.yearMonth, 'YYYY-MM').add(1, 'M').subtract(1, 'd').format('YYYY-MM-DD');
      const filters = {
        client_id: this.details.client_id,
        end_date_from: endDateFrom,
        end_date_until: endDateUntil,
        offset: (this.currentPage - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
        order_descending: true,
        order_by: 'id',
      };

      const { data: { data } } = await this.$store.dispatch('Financial/getStatements', filters);

      this.total = data?.total || 0;
      this.statements = data?.statements.map(stmt => ({
        ...stmt,
        payer_name: stmt.statement_configuration?.payer?.payer_name || '',
        formatted_total_balance: formatValue(stmt.total_balance),
        start_end_date: this.formatDateRange(stmt.start_date, stmt.end_date),
      })) || [];
    },
    openStatementDetails(id) {
      this.$router.push(`/finance/statements/${id}`);
    },
    isPEPM(type) {
      return (type || '').toLowerCase() === 'pepm_fee';
    },
    formatDateRange(startDate, endDate) {
      const startDateStr = formatDatetime(startDate);
      const endDateStr = formatDatetime(endDate);
      return `${startDateStr} - ${endDateStr}`;
    },
    async exportMonthlyRevenueSummary() {
      try {
        const { data } = await this.$store.dispatch('Financial/exportStatementSummaryMerge', {
          selected_ids: (this.details.statements_ids || []).join(','),
        });

        const dateStr = formatDate(new Date(), 'YYYYMMDD_HHmmss');
        const fileName = `revenue_summary_${dateStr}_${this.details.client_name}_${this.details.year_month}.csv`;
        utils.downloadFile(data, fileName);
      } catch (error) {
        let errorMsg = this.translations.global.errors.export_csv;
        if (error.response && error.response.data && error.response.data.error && error.response.data.error.detail) {
          errorMsg = `${errorMsg}: ${error.response.data.error.detail}`;
        }
        this.$noty.error(errorMsg);
      }
    },
    async exportMonthlyRevenueDetails() {
      try {
        const { data } = await this.$store.dispatch('Financial/exportTransactions', {
          statement_ids: (this.details.statements_ids || []).join(','),
        });

        const dateStr = formatDate(new Date(), 'YYYYMMDD_HHmmss');
        const fileName = `revenue_transactions_${dateStr}_${this.details.client_name}_${this.details.year_month}.csv`;
        utils.downloadFile(data, fileName);
      } catch (error) {
        let errorMsg = this.translations.global.errors.export_csv;
        if (error.response && error.response.data && error.response.data.error && error.response.data.error.detail) {
          errorMsg = `${errorMsg}: ${error.response.data.error.detail}`;
        }
        this.$noty.error(errorMsg);
      }
    },
  },
};
</script>
<style>
  .pepm_observations {
    height: 18px;
    width: 18px;
  }

  .pepm-tooltip > .tooltip-inner {
    white-space: pre-line;
    max-width: none !important;
  }
</style>
